<template>
  <CCard :key="formIdentifier" class="col-md-12">
    <CCardHeader>
      <strong>Toollijst</strong>
      <CButton @click="goToGrid" class="btn btn-primary btn-sm" style="float: right">Annuleren</CButton>
      <CButton :disabled="validationErrors.length" @click="onSubmit()" class="btn btn-primary btn-sm" style="float: right; margin-right: 4px"
        >Opslaan</CButton
      >
      <CButton
        v-if="!!id && !data?.hasExchanged"
        :disabled="validationErrors.length"
        @click="readToollijst"
        class="btn btn-primary btn-sm"
        style="float: right; margin-right: 4px"
        >Inlezen</CButton
      >
      <CButton
        v-if="!!id"
        :disabled="validationErrors.length"
        @click="onSubmit(true)"
        class="btn btn-secondary btn-sm"
        style="float: right; margin-right: 40px"
        >Archiveren</CButton
      >
    </CCardHeader>
    <CCardBody>
      <dynamic-form :metadata="metadata" :data="data" :name="formIdentifier" :isEdit="!!id" />
    </CCardBody>
  </CCard>
</template>

<script>
import { useStore } from 'vuex';
import { computed, toRefs, watch } from 'vue';
import DynamicForm from '../dynamicform/DynamicForm.vue';
import CrudService from '../../services/CrudService';
import { useRouter } from 'vue-router';
import moment from 'moment';

export default {
  name: 'ToollijstenCrud',
  components: { DynamicForm },
  props: {
    id: {
      required: false
    },
    machineId: {
      required: false
    },
    naam: {
      required: false
    },
    klant: {
      required: false
    },
    tekeningnummer: {
      required: false
    },
    revisienummer: {
      required: false
    },
    benaming: {
      required: false
    },
    aanmaakdatum: {
      required: false
    },
    inleesprocedure: {
      required: false
    },
    directory: {
      required: false
    },
    bronbestand: {
      required: false
    },
    doelbestand: {
      required: false
    }
  },
  setup(props) {
    const { id: id, machineId: machineId } = toRefs(props);
    const store = useStore();
    const router = useRouter();

    const mainIdentifier = 'toollijsten' + Math.floor(Math.random() * 1000000);
    let formIdentifier = 'form' + Math.floor(Math.random() * 1000000);

    store.commit('toollijstencrud/SET_NEW_TOOLLIJSTEN_FORM', mainIdentifier);
    store.commit('dynamicform/SET_NEW_FORM', formIdentifier);
    store.dispatch('toollijstencrud/getMetadata', { identifier: mainIdentifier, isUpdate: !!id.value });
    if (id?.value) {
      store.dispatch('toollijstencrud/getFieldValues', { id: id.value, identifier: mainIdentifier });
    }

    const metadata = computed(() => store.state.toollijstencrud[mainIdentifier].metadata);
    const data = computed(() => store.state.toollijstencrud[mainIdentifier].fieldValues);
    const loadingStatus = computed(() => store.state.dynamicform[formIdentifier].loadingStatus);
    const validationErrors = computed(() => store.state.dynamicform[formIdentifier].validationErrors);

    const lastPage = computed(() => store.state.lastPage);

    watch(
      [machineId, metadata],
      () => {
        if (props.naam && metadata.value) {
          setTimeout(() => {
            store.state.dynamicform[formIdentifier].fieldValues.toollijstNaam = props.naam;
            store.state.dynamicform[formIdentifier].fieldValues.klant = props.klant;
            store.state.dynamicform[formIdentifier].fieldValues.tekeningnummer = props.tekeningnummer;
            store.state.dynamicform[formIdentifier].fieldValues.revisienummer = props.revisienummer;
            store.state.dynamicform[formIdentifier].fieldValues.aanmaakdatum = props.aanmaakdatum
              ? moment(props.aanmaakdatum.split('.')[0], 'YYYY-MM-DDTHH:mm:ss', true).format('yyyy-MM-DD')
              : props.aanmaakdatum;

            store.state.dynamicform[formIdentifier].fieldValues.benaming = props.benaming;
            store.state.dynamicform[formIdentifier].fieldValues.inleesProcedureId = parseInt(props.inleesprocedure);
            store.state.dynamicform[formIdentifier].fieldValues.directory = props.directory;
            store.state.dynamicform[formIdentifier].fieldValues.bronBestand = props.directory
              ? [props.directory, props.bronbestand]
                  .map(function (i) {
                    return i.replace(/(^\/|\/$)/, '');
                  })
                  .join('\\')
              : props.bronbestand;

            store.state.dynamicform[formIdentifier].fieldValues.doelBestand = props.doelbestand;
            if (machineId.value && machineId.value != '-1' && metadata.value) {
              store.state.dynamicform[formIdentifier].fieldValues.machineId = parseInt(machineId.value);
              store.state.toollijstencrud[mainIdentifier].fieldValues.machineId = parseInt(machineId.value);
            }
          }, 0);
        } else if (machineId.value && machineId.value != '-1' && metadata.value) {
          setTimeout(() => {
            store.state.dynamicform[formIdentifier].fieldValues.machineId = parseInt(machineId.value);
            store.state.toollijstencrud[mainIdentifier].fieldValues.machineId = parseInt(machineId.value);
          }, 0);
        }
      },
      { immediate: true }
    );

    const onSubmit = (archive = false) => {
      store.commit('dynamicform/SET_LOADING_STATUS', { loadingStatus: true, identifier: formIdentifier });
      const isUpdate = !!id.value;
      const body = store.state.dynamicform[formIdentifier].fieldValues;
      body.isArchived = archive;

      const updateOrAdd = () => (isUpdate ? CrudService.putData('toollijsten', id.value, body) : CrudService.postData('toollijsten', body));
      updateOrAdd()
        .then(async (response) => {
          if (response?.data?.success === false) {
            const errorMsg = response?.data?.messages?.filter((m) => m.messageType == 2)[0].text;
            store.commit('ADD_TOAST_ERROR', errorMsg ?? 'Uw wijzigingen konden niet worden opgeslagen.', { root: true });
          } else if (isUpdate) {
            store.commit('ADD_TOAST_SUCCESS', 'Uw wijzigingen zijn opgeslagen.', { root: true });
            if (store.state.toollijstenview?.toollijstenData?.length) {
              const dataIndex = store.state.toollijstenview.toollijstenData.findIndex((x) => x.id == response.data?.value.id);
              if (dataIndex >= 0) {
                if (archive) {
                  store.state.toollijstenview.toollijstenData.splice(dataIndex, 1);
                } else {
                  store.state.toollijstenview.toollijstenData[dataIndex] = response.data?.value;
                }
              }

              if (store.state.toollijstenview.machineSelectedId == body.machineId) {
                const availableIndex = store.state.toollijstenview.toollijstenAvailableToMachine.findIndex((x) => x.id == response.data?.value.id);
                if (availableIndex >= 0) {
                  if (archive) {
                    store.state.toollijstenview.toollijstenAvailableToMachine.splice(availableIndex, 1);
                  } else {
                    store.state.toollijstenview.toollijstenAvailableToMachine[availableIndex] = response.data?.value;
                  }
                }
              }
            }

            goToGrid();
          } else {
            store.commit('ADD_TOAST_SUCCESS', 'Uw toollijst is succesvol toegevoegd.', { root: true });

            if (store.state.toollijstenview?.toollijstenData?.length) {
              store.state.toollijstenview.toollijstenData.push(response?.data?.value);
              if (store.state.toollijstenview.machineSelectedId == body.machineId) {
                store.state.toollijstenview.toollijstenAvailableToMachine.push(response?.data?.value);
              }
            }

            router.push({
              name: 'toollijstencrud',
              params: {
                name: 'toollijsten',
                id: response.data.value.id
              }
            });
          }
        })
        .catch((err) => store.commit('ADD_TOAST_ERROR', err, { root: true }));
    };

    function goToGrid() {
      router.push(
        lastPage.value ?? {
          name: 'toollijsten'
        }
      );
    }

    function readToollijst() {
      CrudService.postData(`toollijsten/read/${id.value}`, store.state.dynamicform[formIdentifier].fieldValues)
        .then((response) => {
          if (response.data.success) {
            if (store.state.toollijstenview?.toollijstenData?.length) {
              const dataIndex = store.state.toollijstenview.toollijstenData.findIndex((x) => x.id == id.value);
              if (dataIndex >= 0) {
                store.state.toollijstenview.toollijstenData[dataIndex] = response.data?.value;
              }

              if (store.state.toollijstenview.toollijstenMachineSelectedId == store.state.dynamicform[formIdentifier].fieldValues.machineId) {
                const availableIndex = store.state.toollijstenview.toollijstenAvailableToMachine.findIndex((x) => x.id == id.value);
                if (availableIndex >= 0) {
                  store.state.toollijstenview.toollijstenAvailableToMachine[availableIndex] = response.data?.value;
                }
              }
            }

            goToGrid();
          }

          store.commit(
            'HANDLE_RESPONSE',
            { response: response.data, displayMultipleErrors: true, successMessage: 'De toollijst is succesvol ingelezen.' },
            { root: true }
          );
        })
        .catch((error) => {
          store.commit('SET_ERROR', 'De toollijst kon niet worden ingelezen.', { root: true });
          throw error;
        });
    }

    return {
      metadata,
      data,
      loadingStatus,
      validationErrors,
      onSubmit,
      goToGrid,
      formIdentifier,
      readToollijst
    };
  }
};
</script>