<template>
  <!-- Disabled is used in a cheaty way - if checkboxes will be used like this in other code, this must be changed! -->
  <input
    type="checkbox"
    @click="checkedHandler($event)"
    :checked="params.value"
    style="margin-top: 6px; height: 75% !important; width: 75% !important"
    :disabled="isDisabled"
  />
</template>

<script>
import { defineComponent, toRefs, computed } from 'vue';

export default defineComponent({
  name: 'CheckboxCellRenderer',
  props: {
    params: {
      required: false
    }
  },
  setup(props) {
    const { params: parameters } = toRefs(props);

    const isDisabled = computed(() => {
      const condition = parameters.value.colDef?.disabledCondition;
      if (condition) {
        return condition(parameters.value.data);
      }
      return false;
    });

    function checkedHandler(event) {
      let checked = event.target.checked;
      let colId = parameters.value.column.colId;
      parameters.value.node.setDataValue(colId, checked);
    }

    /*
        true/false

        {
          value: true,
          disabled: true/false
        }
      */

    return {
      parameters,
      checkedHandler,
      isDisabled
    };
  }
});
</script>