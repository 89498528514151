import CrudService from '../../services/CrudService';

const namespaced = true;

const state = {
  magazijnenMetadata: {}
};

const mutations = {
  SET_MAGAZIJNEN_METADATA(state, payload) {
    payload.properties.push({
      variableName: 'printLabel',
      displayName: 'Print',
      pinned: true,
      displayType: 10,
      disabledConditions: [
        {
          propertyName: 'werkelijkeTool',
          notEmpty: true
        }
      ]
    });
    state.magazijnenMetadata = payload;
  },

  ADD_FETCHED_DATA(state, { payload, identifier, replaceOldEdits }) {
    if (replaceOldEdits) {
      state[identifier].fetchedData = state[identifier].fetchedData.concat(payload);
    } else {
      state[identifier].fetchedData = state[identifier].fetchedData.concat(payload);
    }
  },

  ADD_MACHINE_MAGAZIJNEN(state, { payload, identifier }) {
    state[identifier].magazijnenData = payload;
  },

  SET_MACHINE(state, { payload, identifier }) {
    state[identifier].machine = payload;
  },

  SET_NEW_GRID(state, identifier) {
    state[identifier] = {
      magazijnenData: [],
      fetchedData: [],
      machineNaam: undefined
    };
  }
};

const actions = {
  getMagazijnenMetadata({ commit }) {
    CrudService.getMetadataByName('machinemagazijnen', 'grid')
      .then((response) => {
        response.data.properties.push({
          variableName: 'tool',
          displayName: 'CAM nr',
          displayType: 0,
          displayProperties: ['camToolnummer'],
          displayPropertiesPath: ['tool']
        });
        response.data.properties.push({
          variableName: 'replacement',
          displayName: 'Vervanging',
          displayType: 11
        });
        response.data.properties.push({
          variableName: 'locatie',
          displayName: 'Locatie Uit',
          displayType: 11
        });
        response.data.properties.push({
          variableName: 'finished',
          displayName: 'Afgerond',
          displayType: 10,
          disabledConditions: [
            {
              propertyName: 'werkelijkeToolBeschikbaar',
              equals: false
            },
            {
              propertyName: 'replacement',
              notEmpty: true
            }
          ]
        });

        response.data.properties = response.data.properties.map((p) => {
          if (p.variableName == 'toolnummer') {
            p.displayType = 11;
          }

          return p;
        });
        commit('SET_MAGAZIJNEN_METADATA', response.data);
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  getMagazijnenData({ commit }, { machineId, identifier }) {
    CrudService.getDataById('MachineMagazijnen/versleten', machineId)
      .then(async (response) => {
        if (response.data.success) {
          const defaultOptionsInUse = [];
          for (let i = 0; i < response.data.value.length; i++) {
            const m = response.data.value[i];
            m.dropdownConfiguration = [
              {
                options: m.suggestionWerkelijkeTools,
                displayTextIfNoOptions: 'Voorinstellen',
                useDefaultTextAsValue: true,
                class: 'form-select form-select-sm mb-3',
                defaultOption: 'Voorinstellen',
                isDefaultOptionDisabled: false,
                valuePropertyName: 'replacement',
                label: 'unieknummer'
              }
            ];
            // This code is to avoid duplicate default values - every row gets its own suggestion
            if (m.dropdownConfiguration[0].options.length > 0) {
              for (let o = 0; o < m.dropdownConfiguration[0].options.length; o++) {
                const option = m.dropdownConfiguration[0].options[o];
                if (defaultOptionsInUse.includes(option.unieknummer)) {
                  continue;
                }
                m.replacement = option.unieknummer;
                defaultOptionsInUse.push(option.unieknummer);
                break;
              }
            }
          }

          response.data.value.map((m) => {
            m.werkelijkeToolBeschikbaar = !!m.werkelijkeTool || !!m.replacement;
            m.finished = m.toolId && !m.werkelijkeTool;
            return m;
          });

          commit('ADD_FETCHED_DATA', { payload: response.data.value.filter((m) => m.werkelijkeToolBeschikbaar), identifier });
        }
        commit('HANDLE_RESPONSE', { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  getMachine({ commit }, { machineId, identifier }) {
    CrudService.getDataById('machines', machineId)
      .then((response) => {
        if (response.data.success) {
          commit('SET_MACHINE', { payload: response.data.value, identifier });
        }
        commit('HANDLE_RESPONSE', { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  getMachineMagazijnenByIds({ commit }, { ids, machineId, identifier }) {
    CrudService.getMany(`machinemagazijnen/${machineId}/ids`, ids)
      .then(async (response) => {
        if (response.data.success) {
          const availableLocations = await CrudService.getGridData('locaties/available');
          if (!availableLocations.data.success) {
            commit('HANDLE_RESPONSE', { response: availableLocations.data }, { root: true });
            return;
          }

          response.data.value.forEach((m) => {
            m.dropdownConfiguration = [
              {
                options: m.suggestionWerkelijkeTools,
                displayTextIfNoOptions: 'Niet beschikbaar',
                class: 'form-select form-select-sm mb-3',
                isDefaultOptionDisabled: false,
                valuePropertyName: 'replacement',
                label: 'unieknummer'
              }
            ];

            if (m.werkelijkeTool) {
              m.dropdownConfiguration.push({
                options: [{ code: 'Uw Keuze', locatie: -1 }].concat(availableLocations.data.value),
                class: 'form-select form-select-sm mb-3',
                defaultOption: 'Uw Keuze',
                isDefaultOptionDisabled: false,
                valuePropertyName: 'locatie',
                label: 'code'
              });
            }
            m.edit = true;
            m.replacement = undefined;
            if (m.werkelijkeTool) {
              m.dropdownConfiguration[0].options = [{ unieknummer: 'Verwijderen' }];
            } else {
              m.dropdownConfiguration[0].options = [];
            }
            m.dropdownConfiguration[0].options = m.dropdownConfiguration[0].options.concat(m.suggestionWerkelijkeTools);
          });

          commit('ADD_FETCHED_DATA', { payload: response.data.value, identifier, replaceOldEdits: true });
        }
        commit('HANDLE_RESPONSE', { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  printLabels({ commit }, vervangingen) {
    CrudService.postData(
      'werkelijketools/printlabels',
      vervangingen.map((x) => x.werkelijkeToolId)
    )
      .then((response) => {
        commit('HANDLE_RESPONSE', { response: response.data, successMessage: 'De labels worden geprint.' }, { root: true });
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  }
};

const tabletversletentools = {
  state,
  mutations,
  actions,
  namespaced
};

export default tabletversletentools;
