<template>
  <CRow>
    <CCol>
      <CCard class="mb-3">
        <CCardHeader>
          <a @click="goToWerkvloer">
            <CIcon class="mt-1 text-dark" :icon="cilArrowLeft" size="xl" />
          </a>
          <strong class="ms-3" style="font-size: 20px">Vervang Momenten - {{ machineNaam }}</strong>
        </CCardHeader>
        <CCardBody>
          <CButton
            @click="goToVervangingenHistory"
            :disabled="!vervangMomenten?.filter((x) => x.select)?.length && !versletenHistorySelectedRow"
            color="light"
            class="btn-md mb-2"
          >
            Vervangingen Inzien
          </CButton>
          &nbsp;
          <CButton
            color="primary"
            class="btn-md mb-2"
            style="float: right"
            @click="generateTooloffset"
            :disabled="!vervangMomenten?.filter((x) => x.select)?.length"
          >
            Tooloffset genereren
          </CButton>
          <dynamic-grid
            name="MVersletenHistory"
            :metadata="versletenHistoryMetadata"
            :data="versletenHistoryData"
            :tabletView="true"
            :autoPagination="true"
            heightOfRows="35"
            heightOfTable="550px"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { useStore } from 'vuex';
import DynamicGrid from '@/views/dynamicgrid/DynamicGrid.vue';
import { computed, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { CIcon } from '@coreui/icons-vue';
import { cilArrowLeft } from '@coreui/icons';
import CrudService from '../../services/CrudService';

export default {
  name: 'TabletVersletenHistory',
  components: { DynamicGrid, CIcon },
  props: {
    machineId: {
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const { machineId: machineId } = toRefs(props);

    store.commit('dynamicgrid/SET_NEW_GRID', 'MVersletenHistory');

    store.dispatch('tabletversletenhistory/getVersletenHistoryMetadata');
    store.dispatch('tabletversletenhistory/getVersletenHistoryData', machineId.value);

    const versletenHistoryMetadata = computed(() => store.state.tabletversletenhistory.versletenHistoryMetadata);
    const versletenHistoryData = computed(() => store.state.tabletversletenhistory.versletenHistoryData);
    const machineNaam = computed(() => store.state.tabletversletenhistory.machineNaam);

    const vervangMomenten = computed(() => store.state.dynamicgrid['MVersletenHistory'].rows);

    const versletenHistorySelectedRow = computed(() => {
      if (store.state.dynamicgrid['MVersletenHistory'].selectedRows.length > 0) {
        return store.state.dynamicgrid['MVersletenHistory'].selectedRows[0];
      }
      return undefined;
    });

    function generateTooloffset() {
      const vervangMomentenIds = vervangMomenten.value.filter((x) => x.select).map((row) => row.id);
      CrudService.postData(`vervangmoment/GenerateTooloffset`, { machineId: machineId.value, vervangmomenten: vervangMomentenIds })
        .then((response) => {
          store.commit(
            'HANDLE_RESPONSE',
            { response: response.data, successMessage: 'De vervangmomenten zijn succesvol geëxporteerd als tooloffset bestand.' },
            { root: true }
          );
          goToWerkvloer();
        })
        .catch(() => {
          store.commit('ADD_TOAST_ERROR', 'Er is wat misgegaan met het exporteren van de vervangmomenten als tooloffset bestand.', { root: true });
        });
    }

    function setSelected() {
      let momenten = vervangMomenten.value.filter((x) => x.select).map((x) => x.id);
      if (!momenten?.length) {
        momenten = [versletenHistorySelectedRow.value.id];
      }
      store.commit('tabletvervangingenhistory/SET_VERVANG_MOMENTEN_IDS', momenten);
    }

    function goToVervangingenHistory() {
      setSelected();
      router.push({
        name: 'tabletvervangingenhistory',
        params: {
          machineId: machineId.value
        }
      });
    }

    function goToWerkvloer() {
      router.push({
        name: 'werkvloer'
      });
    }

    return {
      versletenHistoryMetadata,
      versletenHistoryData,
      versletenHistorySelectedRow,
      cilArrowLeft,
      goToVervangingenHistory,
      goToWerkvloer,
      machineNaam,
      setSelected,
      vervangMomenten,
      generateTooloffset
    };
  }
};
</script>