<template>
  <CRow>
    <CCol>
      <CCard class="mb-3">
        <CCardHeader>
          <a @click="goToWerkvloer">
            <CIcon class="mt-1 text-dark" :icon="cilArrowLeft" size="xl" />
          </a>
          <strong class="ms-3" style="font-size: 20px">Toolregister import - Onbekende tools - {{ machineNaam }}</strong>
          <CButton @click="toevoegen" color="primary" class="btn-md" style="float: right"> Doorgaan </CButton>
        </CCardHeader>
        <CCardBody>
          <dynamic-grid
            name="registerimporttools"
            :metadata="registerImportToolsMetadata"
            :data="registerImportToolsData"
            :autoPagination="true"
            heightOfRows="35"
            :multiSelect="true"
            heightOfTable="550px"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { useStore } from 'vuex';
import DynamicGrid from '@/views/dynamicgrid/DynamicGrid.vue';
import { computed, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import CrudService from '@/services/CrudService.js';
import { CIcon } from '@coreui/icons-vue';
import { cilArrowLeft } from '@coreui/icons';

export default {
  name: 'TabletMachineToolRegisterImport',
  components: { DynamicGrid, CIcon },
  props: {
    machineId: {
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const { machineId: machineId } = toRefs(props);

    if (!store.state.dynamicgrid['registerimporttools']) {
      store.commit('dynamicgrid/SET_NEW_GRID', 'registerimporttools');
      store.dispatch('tablettoolregisterimport/getregisterImportToolsMetadata');
    }

    const gridRows = computed(() => store.state.dynamicgrid['registerimporttools'].rows);
    const selectedRows = computed(() => store.state.dynamicgrid['registerimporttools'].selectedRows);

    const toevoegenCams = computed(() => selectedRows.value.filter((row) => row.camtool).map((row) => row.camtool));

    const machineNaam = computed(() => store.state.tablettoolregisterimport.machineNaam);

    const registerImportToolsMetadata = computed(() => store.state.tablettoolregisterimport.registerImportToolsMetadata);
    const registerImportToolsData = computed(() => store.state.tablettoolregisterimport.registerImportToolsData);

    function getData() {
      store.commit('SET_LOADING_STATUS', true, { root: true });
      CrudService.getDataById('ToolRegisterImportTools', machineId.value)
        .then((response) => {
          if (response.data.success) {
            store.commit('tablettoolregisterimport/SET_REGISTER_IMPORT_TOOLS_DATA', response.data.value.unknownTools);
            store.commit('tablettoolregisterimport/SET_MACHINE_NAME', response.data.value.machineNaam);

            if (response.data.value.unknownTools.length == 0) {
              goToMagazijnImport();
            }
          }

          store.commit('HANDLE_RESPONSE', { response: response.data }, { root: true });
        })
        .catch((error) => {
          store.commit('SET_ERROR', error, { root: true });
          throw error;
        })
        .finally(() => store.commit('SET_LOADING_STATUS', false, { root: true }));
    }

    function toevoegen() {
      if (toevoegenCams.value.length == 0) {
        goToMagazijnImport();
        return;
      }
      CrudService.postData(`ToolRegisterImportTools/toevoegen`, { toevoegenCamNummers: toevoegenCams.value, machineId: machineId.value })
        .then((response) => {
          store.commit('HANDLE_RESPONSE', { response: response.data, successMessage: 'De tools zijn succesvol opgeslagen.' }, { root: true });
          if (response.data.success) {
            goToMagazijnImport();
          }
        })
        .catch(() => {
          store.commit('ADD_TOAST_ERROR', 'Er is wat misgegaan met het opslaan van de tools.', { root: true });
        });
    }

    function goToMagazijnImport() {
      store.commit('tablettoolregisterimport/CLEAR_HISTORY');
      router.push({
        name: 'tabletmachinetoolmagazijnimport',
        params: {
          machineId: machineId.value
        }
      });
    }

    function goToWerkvloer() {
      store.commit('tablettoolregisterimport/CLEAR_HISTORY');
      router.push({
        name: 'werkvloer'
      });
    }

    getData();

    return {
      registerImportToolsData,
      registerImportToolsMetadata,
      gridRows,
      toevoegenCams,
      toevoegen,
      machineNaam,
      goToMagazijnImport,
      goToWerkvloer,
      cilArrowLeft
    };
  }
};
</script>