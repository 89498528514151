import CrudService from '../../services/CrudService';

const namespaced = true;

const state = {
  voorinstelRegelsMetadata: [],
  voorinstelRegelsData: [],
  voorinstelOrdersMetadata: [],
  voorinstelOrdersData: [],
  voorinstelOrderRegelsMetadata: [],
  voorinstelOrderRegelsData: [],
  selectedRegelsData: [],
  selectedOrdersData: [],
  machines: [],
  locations: [],
  machineId: -1
};

const mutations = {
  SET_LOCATIONS(state, payload) {
    state.locations = payload;
  },

  SET_VOORINSTEL_REGELS_METADATA(state, payload) {
    state.voorinstelRegelsMetadata = payload;
  },

  SET_VOORINSTEL_REGELS_DATA(state, payload) {
    state.voorinstelRegelsData = payload;
  },

  SET_VOORINSTEL_ORDERS_METADATA(state, payload) {
    state.voorinstelOrdersMetadata = payload;
  },

  SET_VOORINSTEL_ORDERS_DATA(state, payload) {
    state.voorinstelOrdersData = payload;
  },

  ADD_VOORINSTEL_ORDER(state, payload) {
    state.voorinstelOrdersData.push(payload);
    state.selectedOrdersData.push(payload);
  },

  SET_VOORINSTEL_ORDER_REGELS_METADATA(state, payload) {
    state.voorinstelOrderRegelsMetadata = payload;
  },

  SET_VOORINSTEL_ORDER_REGELS_DATA(state, payload) {
    state.voorinstelOrderRegelsData = payload;
  },

  SET_MACHINES_DATA(state, payload) {
    state.machines = payload;
  },

  SET_SELECTED_REGELS_DATA(state, payload) {
    state.selectedRegelsData = payload;
  },

  SET_SELECTED_ORDERS_DATA(state, payload) {
    state.selectedOrdersData = payload;
  },

  REMOVE_ORDER(state, id) {
    const index = state.voorinstelOrdersData.findIndex((order) => order.id === id);
    state.voorinstelOrdersData.splice(index, 1);

    const selectedIndex = state.selectedOrdersData.findIndex((order) => order.id === id);
    state.selectedOrdersData.splice(selectedIndex, 1);
  },

  REMOVE_REGELS_BY_ID(state, ids) {
    ids.forEach((id) => {
      const index = state.voorinstelRegelsData.findIndex((regel) => regel.id === id);
      state.voorinstelRegelsData.splice(index, 1);

      const selectedIndex = state.selectedRegelsData.findIndex((regel) => regel.id === id);
      state.selectedRegelsData.splice(selectedIndex, 1);
    });
  },

  ADD_REGELS_BY_ORDER_ID(state, orderId) {
    let regels = state.voorinstelOrdersData.filter((order) => order.id == orderId)[0].regels;
    regels = regels.map((r) => {
      r.voorinstelOrderId = undefined;
      return r;
    });
    state.voorinstelRegelsData = state.voorinstelRegelsData.concat(regels);
    state.selectedRegelsData = state.selectedRegelsData.concat(regels);
  },

  CHANGE_ORDER_STATUS(state, { orderId, status, regels }) {
    const selectedOrder = state.selectedOrdersData.filter((x) => x.id == orderId)[0];
    selectedOrder.status = status;
    selectedOrder.regels = regels;

    const actualOrder = state.voorinstelOrdersData.filter((x) => x.id == orderId)[0];
    actualOrder.status = status;
    actualOrder.regels = regels;
  },

  SET_SELECTED_MACHINE_ID(state, payload) {
    state.machineId = payload;
  },

  CLEAR_HISTORY(state) {
    state.voorinstelRegelsMetadata = [];
    state.voorinstelRegelsData = [];
    state.voorinstelOrdersMetadata = [];
    state.voorinstelOrdersData = [];
    state.voorinstelOrderRegelsMetadata = [];
    state.voorinstelOrderRegelsData = [];
    state.selectedRegelsData = [];
    state.selectedOrdersData = [];
    state.machines = [];
    state.wissellijstSelectedRegels = [];
  }
};

const actions = {
  getVoorinstelRegelsMetadata({ commit }) {
    CrudService.getMetadataByName('voorinstelorderregels', 'grid')
      .then((response) => {
        response.data.properties.push({
          variableName: 'qrText',
          displayName: 'QR Code',
          displayPropertiesPath: ['werkelijkeTool'],
          displayProperties: ['qrText'],
          displayType: 0,
          editable: true,
          pinned: true
        });
        response.data.properties.push({
          variableName: 'locatie',
          displayName: 'Locatie',
          displayType: 11,
          pinned: true
        });
        commit('SET_VOORINSTEL_ORDER_REGELS_METADATA', response.data);

        const copy = JSON.parse(JSON.stringify(response.data));
        copy.properties = copy.properties.filter(
          (p) =>
            p.variableName != 'qrText' &&
            p.variableName != 'locatie' &&
            p.variableName != 'wWaarde' &&
            p.variableName != 'xWaarde' &&
            p.variableName != 'zWaarde'
        );
        commit('SET_VOORINSTEL_REGELS_METADATA', copy);
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
      });
  },

  getVoorinstelRegelsData({ commit }) {
    CrudService.getGridData('voorinstelorderregels')
      .then((response) => {
        if (response.data.success) {
          commit('SET_VOORINSTEL_REGELS_DATA', response.data.value);
        }

        commit('HANDLE_RESPONSE', { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
      });
  },

  getVoorinstelOrdersMetadata({ commit }) {
    CrudService.getMetadataByName('voorinstelorders', 'grid')
      .then((response) => {
        commit('SET_VOORINSTEL_ORDERS_METADATA', response.data);
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
      });
  },

  getVoorinstelOrdersData({ commit }) {
    CrudService.getGridData('voorinstelorders')
      .then(async (response) => {
        if (response.data.success) {
          const availableLocationsResponse = await CrudService.getGridData('locaties/available');

          commit('HANDLE_RESPONSE', { response: availableLocationsResponse.data }, { root: true });
          if (availableLocationsResponse.data.success) {
            const options = availableLocationsResponse.data.value;
            response.data.value.forEach((order) => {
              order.regels = order.regels.map((regel) => {
                regel.dropdownConfiguration = {
                  options,
                  class: '',
                  defaultOption: 'Kies een locatie',
                  isDefaultOptionDisabled: false,
                  valuePropertyName: 'locatie',
                  label: 'code'
                };
                regel.locatie = regel?.werkelijkeTool?.locatie?.code;
                if (regel.locatie == 'Voorinstellen') regel.locatie = undefined;
                return regel;
              });
            });

            commit('SET_LOCATIONS', availableLocationsResponse.data.value);
          }

          commit('SET_VOORINSTEL_ORDERS_DATA', response.data.value);
        }
        commit('HANDLE_RESPONSE', { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
      });
  },

  getMachines({ commit }) {
    CrudService.getGridData('machines')
      .then((response) => {
        if (response.data.success) {
          commit(
            'SET_MACHINES_DATA',
            response.data.value.sort((a, b) => a.naam.localeCompare(b.naam))
          );
        }
        commit('HANDLE_RESPONSE', { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  createOrder({ commit }, regels) {
    CrudService.postData('voorinstelorders', regels)
      .then((response) => {
        if (response.data.success) {
          commit('ADD_VOORINSTEL_ORDER', response.data.value);
          commit(
            'REMOVE_REGELS_BY_ID',
            regels.map((r) => r.id)
          );
        } else {
          commit('ADD_TOAST_ERROR', response.data.messages[0], { root: true });
        }

        commit('HANDLE_RESPONSE', { response: response.data, successMessage: 'De order is succesvol aangemaakt' }, { root: true });
      })
      .catch((error) => commit('ADD_TOAST_ERROR', error, { root: true }));
  },

  deleteOrder({ commit }, orderId) {
    CrudService.deleteById('voorinstelorders', orderId)
      .then((response) => {
        if (response.data.success) {
          commit('ADD_REGELS_BY_ORDER_ID', orderId);
          commit('REMOVE_ORDER', orderId);
          commit('SET_VOORINSTEL_ORDER_REGELS_DATA', []);
        }
        commit('HANDLE_RESPONSE', { response: response.data, successMessage: 'De order is succesvol verwijderd' }, { root: true });
      })
      .catch((error) => commit('ADD_TOAST_ERROR', error, { root: true }));
  },

  deleteRegels({ commit }, regelsIds) {
    CrudService.deleteMany('voorinstelorderregels', regelsIds)
      .then((response) => {
        if (response.data.success) {
          commit('REMOVE_REGELS_BY_ID', regelsIds);
          commit('ADD_TOAST_SUCCESS', 'De geselecteerde regels zijn succesvol verwijderd.', { root: true });
        }

        commit('HANDLE_RESPONSE', { response: response.data, successMessage: 'De geselecteerde regels zijn succesvol verwijderd' }, { root: true });
      })
      .catch((error) => commit('ADD_TOAST_ERROR', error, { root: true }));
  },

  updateOrder({ commit }, { id, order, regels, status, callback }) {
    // Needed because we are chaging "machine" and "status" and we don't want this to be changed in the dynamic grid store - it is unfortunately a reference ;)
    const orderCopy = JSON.parse(JSON.stringify(order));
    orderCopy.machine = undefined;
    orderCopy.status = status;
    orderCopy.regels = regels.map((regel) => {
      if (regel.werkelijkeTool) {
        regel.werkelijkeTool.qrText = regel.qrText ?? regel.werkelijkeTool.qrText;
      } else {
        regel.werkelijkeTool = { qrText: regel.qrText };
      }

      return regel;
    });

    commit('SET_LOADING_STATUS', true, { root: true });

    CrudService.putData('voorinstelorders', id, orderCopy)
      .then((response) => {
        if (response.data.success) {
          response.data.value.regels.forEach((regel) => {
            const corresponding = orderCopy.regels.filter((x) => x.id == regel.id)[0];
            regel.dropdownConfiguration = corresponding.dropdownConfiguration;
            regel.locatie = corresponding.locatie;
          });
          commit('CHANGE_ORDER_STATUS', { orderId: id, status: orderCopy.status, regels: response.data.value.regels });
        }
        commit('HANDLE_RESPONSE', { response: response.data, successMessage: 'De status is succesvol gewijzigd' }, { root: true });
        callback();
      })
      .catch((error) => commit('ADD_TOAST_ERROR', error, { root: true }))
      .finally(() => commit('SET_LOADING_STATUS', false, { root: true }));
  },

  updateQrCodesAndLocations({ commit }, { rows, orderId }) {
    CrudService.postData(
      'werkelijketools/updateQrCodesAndLocaties',
      rows.map((v) => {
        return { qrCode: v.qrText ?? v.werkelijkeTool?.qrText, werkelijkeToolId: v.werkelijkeToolId, locatie: v.locatie };
      })
    )
      .then((response) => {
        commit('HANDLE_RESPONSE', { response: response.data, successMessage: 'De QR codes en locaties zijn succesvol bijgewerkt.' }, { root: true });

        if (response.data.success) {
          state.voorinstelOrdersData
            .filter((order) => order?.id == orderId)[0]
            .regels.forEach((r) => {
              const correspondingRow = rows.filter((row) => row.id == r.id)[0];
              if (correspondingRow && correspondingRow.qrText && r.werkelijkeTool) {
                r.werkelijkeTool.qrText = correspondingRow.qrText;
                r.locatie = correspondingRow.locatie;
              }
            });
        }
      })
      .catch((error) => commit('ADD_TOAST_ERROR', error, { root: true }));
  }
};

const voorinstelorder = {
  state,
  mutations,
  actions,
  namespaced
};

export default voorinstelorder;
