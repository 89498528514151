<template>
  <CRow>
    <CCol>
      <CCard class="mb-3">
        <CCardHeader>
          <a @click="goToMagazijnen">
            <CIcon class="mt-1 text-dark" :icon="cilArrowLeft" size="xl" />
          </a>
          <strong class="ms-3" style="font-size: 20px">Vervangingen - {{ machine?.naam }}</strong>
          <div style="float: right">
            <span
              v-if="
                !gridRows.filter((row) => !row.finished && (row.werkelijkeToolBeschikbaar || row.werkelijkeToolBeschikbaar === undefined)).length &&
                (!replacementsAreUnique || !locatiesAreUnique || !toolnummersAreUnique)
              "
              class="text-danger"
              style="margin-right: 10px"
              >{{ disabledReason }}</span
            >
            <CButton
              @click="concludeReplacements"
              :disabled="
                gridRows.filter((row) => !row.finished && (row.werkelijkeToolBeschikbaar || row.werkelijkeToolBeschikbaar === undefined)).length ||
                !replacementsAreUnique ||
                !locatiesAreUnique ||
                !toolnummersAreUnique
              "
              color="primary"
              class="btn-md"
            >
              Vervangen Afgerond
            </CButton>
            &nbsp;
            <CButton @click="printLabels" :disabled="!gridRows?.filter((x) => x.printLabel)?.length" color="primary" class="btn-md">
              Print Labels
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody>
          <dynamic-grid
            :name="identifier"
            :metadata="versletenToolsMetadata"
            :data="versletenToolsData"
            :tabletView="true"
            :autoPagination="true"
            heightOfRows="35"
            heightOfTable="550px"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { useStore } from 'vuex';
import DynamicGrid from '@/views/dynamicgrid/DynamicGrid.vue';
import { computed, toRefs, watch, ref } from 'vue';
import CrudService from '@/services/CrudService.js';
import { useRouter } from 'vue-router';
import { CIcon } from '@coreui/icons-vue';
import { cilArrowLeft } from '@coreui/icons';

export default {
  name: 'TabletVersletenTools',
  components: { DynamicGrid, CIcon },
  props: {
    machineId: {
      required: true
    },
    editIds: {
      required: false
    }
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const { machineId: machineId, editIds: editIds } = toRefs(props);
    const identifier = ref('TVersletenTools' + machineId.value);

    watch(machineId, () => {
      identifier.value = 'TVersletenTools' + machineId.value;
    });

    if (!store.state.dynamicgrid[identifier.value]) {
      store.commit('tabletversletentools/SET_NEW_GRID', identifier.value);
      store.commit('dynamicgrid/SET_NEW_GRID', identifier.value);
      store.dispatch('tabletversletentools/getMagazijnenMetadata');
    }

    if (editIds.value?.length) {
      store.dispatch('tabletversletentools/getMachineMagazijnenByIds', {
        ids: editIds.value,
        machineId: machineId.value,
        identifier: identifier.value
      });
    }

    store.state.tabletversletentools[identifier.value].fetchedData = [];
    store.state.tabletversletentools[identifier.value].magazijnenData = [];
    store.dispatch('tabletversletentools/getMagazijnenData', { machineId: machineId.value, identifier: identifier.value });
    store.dispatch('tabletversletentools/getMachine', { machineId: machineId.value, identifier: identifier.value });

    const versletenToolsMetadata = computed(() => store.state.tabletversletentools.magazijnenMetadata);
    const fetchedData = computed(() => store.state.tabletversletentools[identifier.value].fetchedData);
    const versletenToolsData = computed(() => store.state.tabletversletentools[identifier.value].magazijnenData);

    const gridRows = computed(() => store.state.dynamicgrid[identifier.value].rows);

    const machine = computed(() => store.state.tabletversletentools[identifier.value].machine);

    watch(
      [machine, fetchedData],
      () => {
        if (!machine.value || !fetchedData.value?.length) {
          return;
        }
        try {
          const copiedData = JSON.parse(JSON.stringify(fetchedData.value));
          // Get all used toolnummers
          const usedToolnummers = new Set([
            ...copiedData.filter((tool) => tool.toolnummer && tool.toolnummer !== 0).map((tool) => tool.toolnummer),
            ...machine.value.machineMagazijnen.filter((tool) => tool.toolnummer && tool.toolnummer !== 0).map((tool) => tool.toolnummer)
          ]);

          // Get all tools without a toolnummer
          const toolsWithoutToolnummer = copiedData.filter((tool) => !tool.toolnummer || tool.toolnummer === 0);
          const toolsWithToolnummer = copiedData.filter((tool) => tool.toolnummer > 0);

          // Get all available toolnummers
          const availableToolnummers = Array.from({ length: machine.value.totalToolnummers }, (_, i) => i + machine.value.startToolnummers).filter(
            (toolnummer) => !usedToolnummers.has(toolnummer)
          );

          // Assign toolnummers to tools without a toolnummer and add dropdown configuration
          const allAvailable = [...availableToolnummers];

          toolsWithToolnummer.forEach((t) => {
            t.dropdownConfiguration.push({
              options: [
                { key: undefined, label: 'Uw Keuze' },
                { key: t.toolnummer, label: t.toolnummer }
              ].concat(allAvailable.map((d) => ({ key: d, label: d }))),
              displayTextIfNoOptions: t.toolnummer,
              class: 'form-select form-select-sm mb-3',
              valuePropertyName: 'toolnummer',
              label: 'label',
              key: 'key'
            });
          });

          for (const toolWithoutToolnummer of toolsWithoutToolnummer) {
            const nextAvailableToolnummer = availableToolnummers[0];
            availableToolnummers.shift();
            usedToolnummers.add(nextAvailableToolnummer);
            toolWithoutToolnummer.toolnummer = nextAvailableToolnummer;
            if (!toolWithoutToolnummer.dropdownConfiguration) toolWithoutToolnummer.dropdownConfiguration = [];
            toolWithoutToolnummer.dropdownConfiguration.push({
              options: [{ key: undefined, label: 'Uw Keuze' }].concat(allAvailable.map((d) => ({ key: d, label: d }))),
              displayTextIfNoOptions: 'n.v.t.',
              class: 'form-select form-select-sm mb-3',
              defaultOption: undefined,
              isDefaultOptionDisabled: false,
              valuePropertyName: 'toolnummer',
              label: 'label',
              key: 'key'
            });
          }

          // Commit the updated machineMagazijnen to the store
          store.commit('tabletversletentools/ADD_MACHINE_MAGAZIJNEN', { payload: copiedData, identifier: identifier.value });
        } catch (error) {
          console.log(error);
        }
      },
      { immediate: true, deep: true }
    );

    const replacementsAreUnique = computed(() => {
      if (!gridRows.value?.length) {
        return false;
      }
      for (let row of gridRows.value) {
        const replacements = gridRows.value.filter(
          (r) => row.replacement && r.replacement == row.replacement && row.replacement != 'Voorinstellen' && row.replacement != 'Verwijderen'
        );
        if (replacements.length > 1) {
          return false;
        }
      }
      return true;
    });

    const toolnummersAreUnique = computed(() => {
      if (!gridRows.value?.length) {
        return false;
      }
      for (let row of gridRows.value) {
        const toolnummers = gridRows.value.filter((r) => row.toolnummer && row.toolnummer > 0 && r.toolnummer == row.toolnummer);
        if (toolnummers.length > 1) {
          return false;
        }
      }
      return true;
    });

    const locatiesAreUnique = computed(() => {
      if (!gridRows.value?.length) {
        return false;
      }
      for (let row of gridRows.value) {
        const locaties = gridRows.value.filter((r) => row.locatie && r.locatie == row.locatie);
        if (locaties.length > 1) {
          return false;
        }
      }
      return true;
    });

    const disabledReason = computed(() => {
      if (!replacementsAreUnique.value) {
        return 'De gekozen vervangingen zijn niet uniek.';
      }
      if (!locatiesAreUnique.value) {
        return 'De gekozen locaties zijn niet uniek.';
      }

      if (!toolnummersAreUnique.value) {
        return 'De gekozen toolnummers zijn niet uniek.';
      }
      return '';
    });

    function printLabels() {
      store.dispatch(
        'tabletversletentools/printLabels',
        gridRows.value.filter((x) => x.printLabel)
      );
    }

    function concludeReplacements() {
      const request = {
        machineId: machineId.value,
        replacements: gridRows.value
          .filter((r) => r.finished)
          .map((row) => {
            return {
              tmId: row.tmId,
              toolnummer: row.toolnummer,
              replacement: row.replacement,
              locatie: row.locatie == -1 || row.locatie == 'Uw Keuze' ? undefined : row.locatie
            };
          })
      };

      CrudService.postData('MachineMagazijnen/vervangingen', request).then((response) => {
        store.commit('HANDLE_RESPONSE', { response: response.data, successMessage: 'Uw wijzigingen zijn opgeslagen.' }, { root: true });
        if (response.data.success) {
          if (store.state?.toollijstenview) {
            store.commit('toollijstenview/CLEAR_HISTORY');
          }
          router.push({
            name: 'werkvloer'
          });
        }
      });
    }

    function goToMagazijnen() {
      router.push({
        name: 'tabletmachinemagazijnen',
        params: {
          machineId: machineId.value
        }
      });
    }

    return {
      versletenToolsMetadata,
      versletenToolsData,
      concludeReplacements,
      gridRows,
      machine,
      replacementsAreUnique,
      goToMagazijnen,
      cilArrowLeft,
      identifier,
      locatiesAreUnique,
      disabledReason,
      printLabels,
      toolnummersAreUnique
    };
  }
};
</script>