export const AG_GRID_LOCALE_NL = {
  // Set Filter
  selectAll: '(Alles selecteren)',
  searchOoo: 'Zoek...',
  noMatches: 'Geen matches',

  // Number Filter & Text Filter
  filterOoo: 'Filter...',
  equals: 'Gelijk aan',
  notEqual: 'Niet gelijk aan',

  // Number Filter
  lessThan: 'Minder dan',
  greaterThan: 'Groter dan',
  lessThanOrEqual: 'Kleiner dan of gelijk aan',
  greaterThanOrEqual: 'Groter dan of gelijk aan',
  inRange: 'Tussen',
  inRangeStart: 'van',
  inRangeEnd: 'tot',

  // Text Filter
  contains: 'Bevat',
  notContains: 'Bevat geen',
  startsWith: 'Begint met',
  endsWith: 'Eindigt op',

  // Date Filter
  dateFormat: 'dd-mm-yyyy',
  dateFormatOoo: 'dd-mm-yyyy',

  // Filter Conditions
  andCondition: 'EN',
  orCondition: 'OF',

  // Other
  loadingOoo: 'Laden...',
  noRowsToShow: 'Geen data beschikbaar',

  more: 'Meer',
  to: 'tot',
  of: 'van',
  page: 'Pagina',
  nextPage: 'Volgende',
  lastPage: 'Laatste',
  firstPage: 'Eerste',
  previousPage: 'Vorige',

  yes: 'Ja',
  no: 'Nee'
};
