<template>
  <CSidebar :class="sidebarVisible ? '' : 'hide'" position="fixed" selfHiding="md" :visible="sidebarVisible">
    <CSidebarBrand>
      <img :src="logo" class="sidebar-brand-full" height="35" />
    </CSidebarBrand>
    <ul class="sidebar-nav app-menu">
      <div v-for="mainScreen in mainScreens" :key="mainScreen">
        <li class="nav-item" v-if="roles.includes(mainScreen.requiredRole)">
          <a
            style="cursor: pointer"
            :class="currentPage == mainScreen.pageName ? 'nav-link active' : 'nav-link'"
            @click="onMenuItemSelected(mainScreen.pageName, mainScreen.parameterName)"
          >
            <CIcon customClassName="nav-icon" name="cil-grid" />
            {{ mainScreen.displayName }}
          </a>
        </li>
      </div>
      <li class="nav-title" v-if="listScreens.length > 0">Lijsten</li>
      <div v-for="listScreen in listScreens" :key="listScreen">
        <li v-if="roles.includes(listScreen.requiredRole)" style="cursor: pointer" class="nav-item">
          <a
            :class="currentPage == listScreen.pageName ? 'nav-link active' : 'nav-link'"
            @click="onMenuItemSelected(listScreen.pageName, listScreen.parameterName)"
          >
            <CIcon customClassName="nav-icon" name="cil-puzzle" />
            {{ listScreen.displayName }}
          </a>
        </li>
      </div>
      <li class="nav-item">
        <a style="cursor: pointer" class="nav-link" @click="logout">
          <CIcon customClassName="nav-icon" name="cil-account-logout" />
          UITLOGGEN
        </a>
      </li>
    </ul>

    <CSidebarToggler class="d-none d-lg-flex" @click="$store.commit('toggleSidebar')" />
  </CSidebar>
</template>

<script>
import { computed, inject, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  name: 'AppMenu',
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    let keycloak = inject('keycloak');

    const width = Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );

    if (width < 1601) {
      store.commit('toggleSidebar');
    }

    const roles = computed(() => store.state.user.roles);
    const currentPage = ref(router.name);
    function onMenuItemSelected(view, parameterName) {
      currentPage.value = parameterName ?? view;
      router.push({
        name: view,
        params:
          parameterName === undefined
            ? undefined
            : {
                name: parameterName
              }
      });
    }

    function logout() {
      store.commit('logout');
      keycloak.logout();
    }
    return {
      sidebarVisible: computed(() => store.state.sidebarVisible),
      onMenuItemSelected,
      logout,
      roles,
      currentPage,
      logo: computed(() => store.state.logo),
      mainScreens: computed(() => store.state.mainScreens),
      listScreens: computed(() => store.state.listScreens)
    };
  }
};
</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
}
.app-menu {
  margin-bottom: 0;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.app-menu > li:last-of-type {
  margin-top: auto;
}
</style>
