<template>
  <CCard :key="id ?? ordersIdentifier" :class="id ? undefined : 'col-md-8'">
    <CCardHeader>
      <strong>Order</strong>
      <CButton @click="goToGrid" class="btn btn-primary btn-sm ms-2 float-end">Annuleren</CButton>
      <CButton :disabled="orderValidationErrors?.length > 0" @click="onSubmit(false)" class="btn btn-primary btn-sm float-end">Opslaan</CButton>
    </CCardHeader>
    <CRow>
      <CCol :xs="12" :lg="id ? 6 : undefined">
        <CCard style="box-shadow: none">
          <CCardBody class="border border-0">
            <dynamic-form class="mb-3" :name="ordersIdentifier" :metadata="ordersMetadata" :data="ordersData" :isEdit="!!id" />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol v-if="id" :xs="12" :lg="6">
        <CCard class="me-3 mt-3 mb-3">
          <CCardHeader>
            <strong>Opspanningen</strong>
            <CButton :disabled="!opspanningenSelectedRow" @click="removeOpspanning" class="btn btn-danger btn-sm ms-2 float-end">Verwijderen</CButton>
            <CButton :disabled="!opspanningenSelectedRow" @click="goToOpspanningCrud(true)" class="btn btn-primary btn-sm ms-2 float-end"
              >Wijzigen</CButton
            >
            <CButton @click="goToOpspanningCrud(false)" class="btn btn-primary btn-sm float-end">Toevoegen</CButton>
          </CCardHeader>
          <CCardBody>
            <dynamic-grid
              @double-clicked="goToOpspanningCrud(true)"
              :metadata="opspanningenMetadata"
              :data="opspanningenData"
              :name="opspanningenIdentifier"
              :autoPagination="true"
              :maxColumnsBeforeOverflow="6"
            />
          </CCardBody>
        </CCard>
        <CCard class="me-3 mb-3">
          <CCardHeader>
            <strong>Scans</strong>
          </CCardHeader>
          <CCardBody>
            <dynamic-grid :metadata="scansMetadata" :data="scansData" :name="scansIdentifier" :autoPagination="true" :maxColumnsBeforeOverflow="6" />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CCard>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref, toRefs, watch } from 'vue';
import DynamicForm from '../dynamicform/DynamicForm.vue';
import DynamicGrid from '../dynamicgrid/DynamicGrid.vue';
import CrudService from '../../services/CrudService';
import { useRouter } from 'vue-router';

export default {
  name: 'OrdersCrud',
  components: { DynamicForm, DynamicGrid },
  props: {
    id: {
      required: false
    },
    identifier: {
      required: false
    }
  },
  setup(props) {
    const { id: id, identifier: identifier } = toRefs(props);
    const store = useStore();
    const router = useRouter();

    const baseIdentifier = ref(identifier.value ?? Math.floor(Math.random() * 1000000));

    const ordersIdentifier = ref('orders' + baseIdentifier.value);
    const opspanningenIdentifier = ref('opspanningen' + baseIdentifier.value);
    const scansIdentifier = ref('scans' + baseIdentifier.value);

    if (!id.value && !store.state.orderscrud[ordersIdentifier.value]) {
      store.commit('dynamicform/SET_NEW_FORM', ordersIdentifier.value);
      store.commit('orderscrud/SET_NEW_ORDER_FORM', ordersIdentifier.value);
      store.dispatch('orderscrud/getOrdersMetadata', !!id.value);
    }

    watch(
      [id, identifier],
      () => {
        baseIdentifier.value = identifier.value ?? Math.floor(Math.random() * 1000000);
        ordersIdentifier.value = 'orders' + baseIdentifier.value;
        opspanningenIdentifier.value = 'opspanningen' + baseIdentifier.value;
        scansIdentifier.value = 'scans' + baseIdentifier.value;
        if (!store.state.orderscrud[ordersIdentifier.value]) {
          store.commit('dynamicgrid/SET_NEW_GRID', opspanningenIdentifier.value);
          store.commit('dynamicgrid/SET_NEW_GRID', scansIdentifier.value);
          store.commit('dynamicform/SET_NEW_FORM', ordersIdentifier.value);
          store.commit('orderscrud/SET_NEW_ORDER_FORM', ordersIdentifier.value);
        }
        if (!store.state.orderscrud[ordersIdentifier.value]?.ordersData?.id) {
          store.dispatch('orderscrud/getOpspanningenMetadata');
          store.dispatch('orderscrud/getScansMetadata');
          store.dispatch('orderscrud/getOrdersMetadata', !!id.value);

          if (id?.value) {
            store.dispatch('orderscrud/getOrderData', { id: id.value, identifier: ordersIdentifier.value });
          }
        }
      },
      { immediate: true }
    );

    const ordersMetadata = computed(() => store.state.orderscrud.ordersMetadata);
    const ordersData = computed(() => store.state.orderscrud[ordersIdentifier.value].ordersData);
    const orderValidationErrors = computed(() => store.state.dynamicform[ordersIdentifier.value].validationErrors);

    const opspanningenMetadata = computed(() => store.state.orderscrud.opspanningenMetadata);
    const opspanningenData = computed(() => store.state.orderscrud[ordersIdentifier.value].opspanningenData);

    const scansMetadata = computed(() => store.state.orderscrud.scansMetadata);
    const scansData = computed(() => store.state.orderscrud[ordersIdentifier.value].scansData);

    const opspanningenSelectedRow = computed(() => {
      if (store.state.dynamicgrid[opspanningenIdentifier.value].selectedRows.length > 0) {
        return store.state.dynamicgrid[opspanningenIdentifier.value].selectedRows[0];
      }
      return undefined;
    });

    const scansSelectedRow = computed(() => {
      if (store.state.dynamicgrid[scansIdentifier.value].selectedRows.length > 0) {
        return store.state.dynamicgrid[scansIdentifier.value].selectedRows[0];
      }
      return undefined;
    });

    const lastPage = computed(() => store.state.lastPage);

    function removeOpspanning() {
      CrudService.deleteById('opspanningen', opspanningenSelectedRow.value.id)
        .then((response) => {
          if (response.data.success) {
            const indexToRemove = store.state.dynamicgrid[opspanningenIdentifier.value].rows.findIndex(
              (value) => value === opspanningenSelectedRow.value
            );
            store.state.dynamicgrid[opspanningenIdentifier.value].rows.splice(indexToRemove, 1);
            store.state.orderscrud[ordersIdentifier.value].ordersData.opspanningen = store.state.dynamicgrid[opspanningenIdentifier.value].rows;
            store.state.dynamicgrid[opspanningenIdentifier.value].selectedRows = [];
          }
          store.commit(
            'HANDLE_RESPONSE',
            { response: response.data, successMessage: 'De geselecteerde opspanning is succesvol verwijderd.' },
            { root: true }
          );
        })
        .catch((err) => store.commit('ADD_TOAST_ERROR', err, { root: true }));
    }

    function onSubmit(stayOnPage = true) {
      const isUpdate = !!id.value;
      const body = store.state.dynamicform[ordersIdentifier.value].fieldValues;

      const updateOrAdd = () => (isUpdate ? CrudService.putData('orders', id.value, body) : CrudService.postData('orders', body));

      updateOrAdd()
        .then(async (response) => {
          if (!response.data.success) {
            store.commit('HANDLE_RESPONSE', { response: response.data }, { root: true });
            return;
          }
          if (isUpdate) {
            store.commit('ADD_TOAST_SUCCESS', 'Uw wijzigingen zijn opgeslagen.', { root: true });
            if (!stayOnPage) {
              goToGrid();
            }
          } else {
            store.commit('ADD_TOAST_SUCCESS', 'Uw order is succesvol toegevoegd.', { root: true });

            router.push({
              name: 'orderscrud',
              params: {
                id: response.data.value.id
              }
            });
          }
        })
        .catch((err) => store.commit('ADD_TOAST_ERROR', err, { root: true }));
    }

    function goToGrid() {
      router.push(
        lastPage.value ?? {
          name: 'dynamicgridview',
          params: {
            name: 'orders'
          }
        }
      );
    }

    function goToOpspanningCrud(isEdit) {
      router.push({
        name: 'opspanningencrud',
        params: {
          isEdit: isEdit,
          identifier: baseIdentifier.value,
          orderId: id.value
        }
      });
    }

    return {
      ordersMetadata,
      ordersData,
      opspanningenMetadata,
      opspanningenData,
      scansMetadata,
      scansData,
      orderValidationErrors,
      onSubmit,
      goToGrid,
      goToOpspanningCrud,
      removeOpspanning,
      ordersIdentifier,
      opspanningenIdentifier,
      scansIdentifier,
      opspanningenSelectedRow,
      scansSelectedRow
    };
  }
};
</script>