<template>
  <CRow>
    <CCol>
      <DropZone @filesloaded="onFilesSelected" />
    </CCol>
  </CRow>
  <CRow class="mt-3" v-for="row in rows" :key="row">
    <CCol class="m-2" v-for="file in row" :key="file">
      <a class="position-relative" v-if="file?.src">
        <CButton class="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-info" @click.prevent="downloadToDisk(file)">
          <CIcon :icon="cilCloudDownload" size="sm" />
        </CButton>
        <CButton
          style="color: white !important"
          :disabled="file.isMainImage"
          v-if="mainImageConfig && file.contentType.includes('image')"
          class="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-success"
          @click.prevent="setAsMainImage(file)"
        >
          <CIcon :icon="cilStar" size="sm" />
        </CButton>
        <CButton class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" @click.prevent="deleteFile(file)">
          <CIcon :icon="cilTrash" size="sm" />
        </CButton>
        <CRow @click="loadModal(file)">
          <CImage
            style="object-fit: contain"
            :class="file.isMainImage ? 'border border-success border-2' : 'border border-light'"
            height="150"
            :src="file?.src"
            rounded
          />
        </CRow>
        <CRow>
          <label>{{ file?.originalName?.substring(0, 20) ?? 'Geen bestandsnaam beschikbaar' }}</label>
        </CRow>
      </a>
    </CCol>
    <div :style="imageModalActive ? undefined : 'display:none'">
      <CModal
        size="md"
        :visible="imageModalActive"
        @close="
          () => {
            imageModalActive = false;
          }
        "
      >
        <CModalHeader>
          <CModalTitle>{{ modalImage.src ? modalImage.originalName ?? 'Geen bestandsnaam beschikbaar' : 'Geen afbeelding beschikbaar' }}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CImage v-if="modalImage?.src" style="object-fit: contain" rounded class="img-fluid border border-light" :src="modalImage.src" />
        </CModalBody>
      </CModal>
    </div>
  </CRow>
</template>

<script>
import DropZone from '@/components/DropZone.vue';
import ApiClient from '../services/ApiClient';
import DownloadClient from '../services/DownloadClient';
import { CIcon } from '@coreui/icons-vue';
import { cilTrash, cilCloudDownload, cilStar } from '@coreui/icons';
import { ref, toRefs, watch, computed } from 'vue';
import { createUrl } from '@/helpers/apiHelper.js';

export default {
  name: 'MultiFileViewer',
  components: {
    DropZone,
    CIcon
  },
  props: {
    modelValue: {
      required: false
    },
    baseUrl: {
      required: true
    },
    downloadConfig: {
      required: true
    },
    uploadConfig: {
      required: true
    },
    deleteConfig: {
      required: true
    },
    mainImageConfig: {
      required: false
    },
    requiredVariable: {
      required: false
    }
  },
  emits: [
    'uploadFailed',
    'downloadFailed',
    'deleteFailed',
    'uploadSucceeded',
    'downloadSucceeded',
    'deleteSucceeded',
    'setMainImageSucceeded',
    'setMainImageFailed',
    'update:modelValue'
  ],
  setup(props, { emit }) {
    const {
      modelValue: modelValue,
      downloadConfig: downloadConfig,
      uploadConfig: uploadConfig,
      deleteConfig: deleteConfig,
      mainImageConfig: mainImageConfig,
      requiredVariable: requiredVariable
    } = toRefs(props);

    const filesToDisplay = ref([]);
    const imageModalActive = ref(false);
    const modalImage = ref({});

    const rows = computed(() => {
      let result = [];
      for (let i = 0; i < filesToDisplay.value.length; i = i + 3) {
        result.push([filesToDisplay.value[i], filesToDisplay.value[i + 1], filesToDisplay.value[i + 2]]);
      }
      return result;
    });

    watch(
      modelValue,
      () => {
        if (modelValue.value != filesToDisplay.value) {
          if (modelValue.value) {
            modelValue.value.forEach((file) => download(file, true));
          }
        }
      },
      { immediate: true }
    );

    function onFilesSelected(files) {
      files.forEach((file) => {
        upload(file);
      });
    }

    function showImage(fileDetails, file) {
      if (fileDetails.contentType.includes('image')) {
        const fileReader = new FileReader();
        fileReader.addEventListener('load', () => {
          fileDetails.src = fileReader.result;
          filesToDisplay.value.push(fileDetails);
        });
        fileReader.readAsDataURL(file);
      } else {
        fileDetails.src = '/file-icon.jpg';
        filesToDisplay.value.push(fileDetails);
      }
      emit('update:modelValue', filesToDisplay.value);
    }

    function upload(file) {
      const url = createUrl(uploadConfig.value, undefined, requiredVariable.value);
      const formData = new FormData();
      formData.append('file', file);
      ApiClient()
        .post(url, formData)
        .then((response) => {
          showImage(response.data, file);
        })
        .catch((error) => emit('uploadFailed', { model: file, error, url }));
    }

    function download(fileDetails, isThumbnail, forModal) {
      if (fileDetails.contentType.includes('image')) {
        let url = createUrl(downloadConfig.value, fileDetails, requiredVariable.value);
        url += `&tryDownloadThumbnail=${isThumbnail}`;
        DownloadClient()
          .get(url, { responseType: 'arraybuffer' })
          .then((response) => {
            const imageBinary = 'data:image/.jpeg;base64,' + Buffer.from(response.data, 'binary').toString('base64');
            if (forModal) {
              imageModalActive.value = true;
              modalImage.value.src = imageBinary;
              modalImage.value.actualImageLoaded = true;
            } else {
              fileDetails.src = imageBinary;
              filesToDisplay.value.push(fileDetails);
              emit('update:modelValue', filesToDisplay.value);
            }
          })
          .catch((error) => emit('downloadFailed', { url, error }));
      } else {
        fileDetails.src = '/file-icon.jpg';
        filesToDisplay.value.push(fileDetails);
        emit('update:modelValue', filesToDisplay.value);
      }
    }

    function downloadToDisk(fileDetails) {
      const url = createUrl(downloadConfig.value, fileDetails, requiredVariable.value);
      DownloadClient()
        .get(url, { responseType: 'arraybuffer' })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: fileDetails.contentType }));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', fileDetails.originalName);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    }

    function deleteFile(fileDetails) {
      const url = createUrl(deleteConfig.value, fileDetails, requiredVariable.value);
      ApiClient()
        .delete(url)
        .then((response) => {
          const indexToDelete = filesToDisplay.value.findIndex((file) => file.id === fileDetails.id);
          filesToDisplay.value.splice(indexToDelete, 1);
          emit('deleteSucceeded', { fileDetails, response });
          emit('update:modelValue', filesToDisplay.value);
        })
        .catch((error) => emit('deleteFailed', { fileDetails, error }));
    }

    function setAsMainImage(file) {
      const url = createUrl(mainImageConfig.value, file, requiredVariable.value);
      ApiClient()
        .put(url)
        .then(() => {
          filesToDisplay.value.forEach((f) => {
            f.isMainImage = f.id == file.id;
          });
          emit('setMainImageSucceeded', { file });
        })
        .catch((error) => emit('setMainImageFailed', { file, error }));
    }

    function loadModal(file) {
      if (!file.contentType.includes('image')) {
        imageModalActive.value = false;
      } else {
        modalImage.value = file;

        if (!modalImage.value.actualImageLoaded) {
          download(file, false, true);
        } else {
          imageModalActive.value = true;
        }
      }
    }

    return {
      filesToDisplay,
      rows,
      onFilesSelected,
      downloadToDisk,
      deleteFile,
      cilCloudDownload,
      cilTrash,
      cilStar,
      imageModalActive,
      modalImage,
      setAsMainImage,
      loadModal
    };
  }
};
</script>