import ApiClient from './ApiClient';

export default {
  getToollijsten(machineId) {
    return ApiClient().get(`/toollijsten?machineId=${machineId || ''}`);
  },

  getToollijstenInMachine(machineId) {
    return ApiClient().get(`/machinelijsten/inMachine/${machineId}`);
  },

  getToollijst(id, includeChildren = false) {
    return ApiClient().get(`/toollijsten/${id}?includeChildren=${includeChildren}`);
  },

  getToollijstTool(id) {
    return ApiClient().get(`/toollijsttools/${id}`);
  },

  createToollijst(data) {
    return ApiClient().post('/toollijsten', data);
  },

  updateToollijst(data) {
    return ApiClient().put('/toollijsten', data);
  },

  createToollijstTool(data) {
    return ApiClient().post('/toollijsttools', data);
  },

  updateToollijstTool(data) {
    return ApiClient().put('/toollijsttools', data);
  },

  getMachineToollijstPerTool(tmId, machineId) {
    return ApiClient().get(`/machinetoollijstpertool/${tmId}/${machineId}`);
  },

  getMachineLijsten(machineId) {
    return ApiClient().get(`/machinelijsten/inmachine/${machineId}`);
  },

  getMagazijnen(machineId) {
    return ApiClient().get(`/machinemagazijnen/byMachine/${machineId}`);
  }
};
