import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { basicSetup } from 'codemirror';
import VueCodemirror from 'vue-codemirror';
import vSelect from 'vue-select';
import Multiselect from 'vue-multiselect';

import CoreuiVue from '@coreui/vue-pro';
import { CIcon } from '@coreui/icons-vue';
import { iconsSet as icons } from '@/assets/icons';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { AgGridVue } from 'ag-grid-vue3';
import Keycloak from 'keycloak-js';

import Toaster from '@meforma/vue-toaster';
import ConfigService from './services/ConfigService';
import ApiClient from './services/ApiClient';
import 'vue-select/dist/vue-select.css';

(async () => {
  let response = await ConfigService.getConfig();

  store.commit('SET_BASEURL', response.data.baseUrl);
  store.commit('SET_KEYCLOAK_SETTINGS', response.data.keycloakSettings);
  store.commit('SET_LOGO', response.data.logo);
  store.commit('SET_MAIN_SCREENS', response.data.mainScreens);
  store.commit('SET_LIST_SCREENS', response.data.listScreens);

  let initOptions = {
    url: store.state.keycloakSettings.authUrl,
    realm: store.state.keycloakSettings.realm,
    clientId: store.state.keycloakSettings.clientId,
    onLoad: 'login-required',
    checkLoginIframe: false
  };

  store.commit('SET_BASEURL', store.state.baseURL);

  let keycloak = Keycloak(initOptions);
  keycloak
    .init(initOptions)
    .then(async (auth) => {
      if (!auth) {
        window.location.reload();
        return;
      }

      let payload = {
        idToken: keycloak.idToken,
        accessToken: keycloak.token,
        name: keycloak.idTokenParsed.preferred_username,
        roles: keycloak.resourceAccess[initOptions.clientId]?.roles ?? []
      };

      if (keycloak.token && keycloak.idToken && keycloak.token != '' && keycloak.idToken != '') {
        store.commit('login', payload);
        ApiClient()
          .get('frontend/version')
          .then((response) => store.commit('SET_VERSION', response.data))
          .catch((error) => console.error(error));
      } else {
        store.commit('logout');
      }

      const app = createApp(App);
      app.use(store);
      app.use(CoreuiVue);
      app.use(Toaster, { position: 'top-right' });
      app.use(keycloak);
      app.use(router);

      app.use(VueCodemirror, {
        autofocus: true,
        disabled: false,
        indentWithTab: true,
        tabSize: 2,
        placeholder: 'Code goes here...',
        extensions: [basicSetup]
      });
      app.provide('icons', icons);
      app.provide('toast', app.config.globalProperties.$toast);
      app.provide('keycloak', keycloak);
      app.component('CIcon', CIcon);
      app.component('VSelect', vSelect);
      app.component('MultiSelect', Multiselect);
      app.component('AgGridVue', AgGridVue);
      app.mount('#app');

      setInterval(() => {
        keycloak
          .updateToken(60)
          .then((refreshed) => {
            if (refreshed) {
              store.commit('setAccessToken', keycloak.token);
            }
          })
          .catch(() => {
            console.error('Failed to refresh token');
          });
      }, 100);
    })
    .catch((err) => {
      console.error(err);
    });
})();
