<template>
  <CCard :key="id ?? toolsIdentifier" :class="id ? undefined : 'col-md-8'">
    <CCardHeader>
      <strong>Tool</strong>
      <CButton @click="goToGrid" class="btn btn-primary btn-sm ms-2 float-end">Annuleren</CButton>
      <CButton :disabled="toolsValidationErrors?.length > 0" @click="onSubmit(false)" class="btn btn-primary btn-sm float-end">Opslaan</CButton>
    </CCardHeader>
    <CRow>
      <CCol :xs="12" :lg="id ? 6 : undefined">
        <CCard>
          <CCardBody class="rounded-start">
            <dynamic-form class="mb-3" :name="toolsIdentifier" :metadata="toolsMetadata" :data="toolsData" :isEdit="!!id" />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol v-if="id" :xs="12" :lg="6">
        <CCard class="me-3 mt-3 mb-3">
          <CCardHeader>
            <strong>Beschikbare componenten</strong>
          </CCardHeader>
          <CCardBody>
            <dynamic-grid :name="availableComponentsIdentifier" :metadata="componentsMetadata" :data="availableComponents" :autoPagination="true" />
          </CCardBody>
        </CCard>
        <CButton :disabled="availableComponentsSelectedRow?.id === undefined" @click="addComponentToTool" class="btn btn-primary btn-sm mb-3"
          >Toevoegen aan tool</CButton
        >
        &nbsp;
        <CButton :disabled="componentsInToolSelectedRow?.id === undefined" @click="removeComponentFromTool" class="btn btn-primary btn-sm mb-3"
          >Verwijderen uit tool</CButton
        >
        <CCard class="me-3 mb-3">
          <CCardHeader>
            <strong>Componenten in tool</strong>
          </CCardHeader>
          <CCardBody>
            <dynamic-grid :name="componentsInToolIdentifier" :metadata="componentMetadata" :data="currentComponentsInTool" :autoPagination="true" />
          </CCardBody>
        </CCard>
        <CCard class="me-3 mb-3">
          <CCardHeader>
            <strong>Werkelijke tools</strong>
            <CButton :disabled="!werkelijkeToolsSelectedRow" @click="removeWerkelijkeToolFromTool" class="btn btn-danger btn-sm ms-2 float-end"
              >Verwijderen</CButton
            >
            <CButton @click="printLabels" :disabled="!wTGridRows?.filter((x) => x.printLabel)?.length" color="primary" class="btn-sm ms-2 float-end">
              Print Labels
            </CButton>
            <CButton :disabled="!werkelijkeToolsSelectedRow" @click="goToWerkelijkeToolsCrud(true)" class="btn btn-primary btn-sm ms-2 float-end"
              >Wijzigen</CButton
            >
            <CButton @click="goToWerkelijkeToolsCrud(false)" class="btn btn-primary btn-sm float-end">Toevoegen</CButton>
          </CCardHeader>
          <CCardBody>
            <dynamic-grid
              @double-clicked="goToWerkelijkeToolsCrud(true)"
              :name="werkelijkeToolsIdentifier"
              :metadata="werkelijkeToolsMetadata"
              :data="werkelijkeToolsData"
              :autoPagination="true"
              :maxColumnsBeforeOverflow="6"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CCard>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref, toRefs, watch } from 'vue';
import DynamicForm from '../dynamicform/DynamicForm.vue';
import DynamicGrid from '../dynamicgrid/DynamicGrid.vue';
import ToolService from '../../services/ToolService';
import { useRouter } from 'vue-router';
import CrudService from '../../services/CrudService';

export default {
  name: 'ToolsCrud',
  components: { DynamicForm, DynamicGrid },
  props: {
    id: {
      required: false
    },
    identifier: {
      required: false
    }
  },
  setup(props) {
    const { id: id, identifier: identifier } = toRefs(props);
    const store = useStore();
    const router = useRouter();

    const baseIdentifier = ref(identifier.value ?? Math.floor(Math.random() * 1000000));

    const availableComponentsIdentifier = ref('availableComponents' + baseIdentifier.value);
    const componentsInToolIdentifier = ref('componentsInTool' + baseIdentifier.value);
    const toolsIdentifier = ref('tools' + baseIdentifier.value);
    const werkelijkeToolsIdentifier = ref('werkelijkeTools' + baseIdentifier.value);

    if (!id.value && !store.state.toolscrud[toolsIdentifier.value]) {
      store.commit('dynamicform/SET_NEW_FORM', toolsIdentifier.value);
      store.commit('toolscrud/SET_NEW_TOOLS_FORM', toolsIdentifier.value);
      store.dispatch('toolscrud/getToolsMetadata');
    }

    watch(
      [id, identifier],
      () => {
        baseIdentifier.value = identifier.value ?? Math.floor(Math.random() * 1000000);
        availableComponentsIdentifier.value = 'availableComponents' + baseIdentifier.value;
        componentsInToolIdentifier.value = 'componentsInTool' + baseIdentifier.value;
        toolsIdentifier.value = 'tools' + baseIdentifier.value;
        werkelijkeToolsIdentifier.value = 'werkelijkeTools' + baseIdentifier.value;
        if (!store.state.toolscrud[toolsIdentifier.value]) {
          store.commit('dynamicgrid/SET_NEW_GRID', availableComponentsIdentifier.value);
          store.commit('dynamicgrid/SET_NEW_GRID', componentsInToolIdentifier.value);
          store.commit('dynamicgrid/SET_NEW_GRID', werkelijkeToolsIdentifier.value);
          store.commit('dynamicform/SET_NEW_FORM', toolsIdentifier.value);
          store.commit('toolscrud/SET_NEW_TOOLS_FORM', toolsIdentifier.value);
        }
        if (!store.state.toolscrud[toolsIdentifier.value]?.toolsData?.id) {
          store.dispatch('toolscrud/getComponentsMetadata');
          store.dispatch('toolscrud/getToolsMetadata', id.value);
          store.dispatch('toolscrud/getWerkelijkeToolsMetadata');
          store.dispatch('toolscrud/getComponentsData', toolsIdentifier.value);

          if (id?.value) {
            store.dispatch('toolscrud/getToolData', { id: id.value, identifier: toolsIdentifier.value });
          }
          store.dispatch('toolscrud/getComponentsData', toolsIdentifier.value);
        }
      },
      { immediate: true }
    );

    if (id.value) {
      store.dispatch('toolscrud/getComponentsData', toolsIdentifier.value);
    }

    const toolsMetadata = computed(() => store.state.toolscrud.toolsMetadata);
    const toolsData = computed(() => store.state.toolscrud[toolsIdentifier.value].toolsData);
    const toolsLoadingStatus = computed(() => store.state.dynamicform[toolsIdentifier.value].loadingStatus);
    const toolsValidationErrors = computed(() => store.state.dynamicform[toolsIdentifier.value].validationErrors);

    const componentMetadata = computed(() => store.state.toolscrud.componentMetadata);
    const componentsInTool = computed(() => store.state.dynamicgrid[componentsInToolIdentifier.value]?.rows);
    const oldComponentsInTool = computed(() => store.state.toolscrud[toolsIdentifier.value].oldComponentsInTool);
    const currentComponentsInTool = computed(() => store.state.toolscrud[toolsIdentifier.value].currentComponentsInTool);

    const werkelijkeToolsMetadata = computed(() => store.state.toolscrud.werkelijkeToolsMetadata);
    const werkelijkeToolsData = ref([]);

    const componentsMetadata = computed(() => store.state.toolscrud.componentsMetadata);
    const componentsData = computed(() => store.state.toolscrud[toolsIdentifier.value].componentsData);
    const availableComponents = ref([]);

    const wTGridRows = computed(() => store.state.dynamicgrid[werkelijkeToolsIdentifier.value].rows);

    const availableComponentsSelectedRow = computed(() => {
      if (store.state.dynamicgrid[availableComponentsIdentifier.value].selectedRows.length > 0) {
        return store.state.dynamicgrid[availableComponentsIdentifier.value].selectedRows[0];
      }
      return undefined;
    });

    const componentsInToolSelectedRow = computed(() => {
      if (store.state.dynamicgrid[componentsInToolIdentifier.value].selectedRows.length > 0) {
        return store.state.dynamicgrid[componentsInToolIdentifier.value].selectedRows[0];
      }
      return undefined;
    });

    const werkelijkeToolsSelectedRow = computed(() => {
      if (store.state.dynamicgrid[werkelijkeToolsIdentifier.value].selectedRows.length > 0) {
        return store.state.dynamicgrid[werkelijkeToolsIdentifier.value].selectedRows[0];
      }
      return undefined;
    });

    const lastPage = computed(() => store.state.lastPage);

    function onSubmit(stayOnPage = true) {
      store.commit('dynamicform/SET_LOADING_STATUS', { loadingStatus: true, identifier: toolsIdentifier.value });
      const isUpdate = !!id.value;
      const body = store.state.dynamicform[toolsIdentifier.value].fieldValues;

      if (isUpdate) {
        body.werkelijkeTools = store.state.dynamicgrid[werkelijkeToolsIdentifier.value].rows.map((x) => {
          x.machine = undefined;
          return x;
        });
        body.components = componentsInTool.value
          .filter((row) => row.aantal !== undefined && parseInt(row.aantal) > 0)
          .map((row) => {
            return { id: row.id, aantal: parseInt(row.aantal) };
          });
      }

      const updateOrAdd = () => (isUpdate ? ToolService.putTool(body, id.value) : ToolService.postTool(body));

      updateOrAdd()
        .then(async (response) => {
          if (!response.data.success) {
            store.commit('HANDLE_RESPONSE', { response: response.data }, { root: true });
            return;
          }
          if (isUpdate) {
            store.commit('ADD_TOAST_SUCCESS', 'Uw wijzigingen zijn opgeslagen.', { root: true });
            if (!stayOnPage) {
              goToGrid();
            }
          } else {
            store.commit('ADD_TOAST_SUCCESS', 'Uw tool is succesvol toegevoegd.', { root: true });
            store.state.toolscrud[toolsIdentifier.value].toolsData.id = response.data.value.id;

            router.push({
              name: 'toolscrud',
              params: {
                id: response.data.value.id
              }
            });
          }
        })
        .catch((err) => store.commit('ADD_TOAST_ERROR', err, { root: true }))
        .finally(() => store.commit('dynamicform/SET_LOADING_STATUS', { loadingStatus: false, identifier: toolsIdentifier.value }));
    }

    function addComponentToTool() {
      const row = availableComponentsSelectedRow.value;
      row.aantal = 1;
      store.state.dynamicgrid[componentsInToolIdentifier.value].rows.push(row);
      store.state.toolscrud[toolsIdentifier.value].currentComponentsInTool.push(row);
      const indexToRemove = store.state.dynamicgrid[availableComponentsIdentifier.value].rows.findIndex(
        (value) => value === availableComponentsSelectedRow.value
      );
      store.state.dynamicgrid[availableComponentsIdentifier.value].rows.splice(indexToRemove, 1);
      store.state.dynamicgrid[availableComponentsIdentifier.value].selectedRows = [];
      onSubmit();
    }

    function removeComponentFromTool() {
      CrudService.deleteByQueries('components', [
        {
          queryName: 'toolId',
          queryValue: id.value
        },
        {
          queryName: 'componentId',
          queryValue: componentsInToolSelectedRow.value.id
        }
      ])
        .then((response) => {
          if (response.data.success) {
            store.state.dynamicgrid[availableComponentsIdentifier.value].rows.push(componentsInToolSelectedRow.value);
            const indexToRemove = store.state.dynamicgrid[componentsInToolIdentifier.value].rows.findIndex(
              (value) => value === componentsInToolSelectedRow.value
            );
            store.state.dynamicgrid[componentsInToolIdentifier.value].rows.splice(indexToRemove, 1);
            store.state.toolscrud[toolsIdentifier.value].currentComponentsInTool.splice(indexToRemove, 1);

            store.state.toolscrud[toolsIdentifier.value].currentComponentsInTool = store.state.dynamicgrid[componentsInToolIdentifier.value].rows;
            store.state.dynamicgrid[componentsInToolIdentifier.value].selectedRows = [];
          }

          store.commit(
            'HANDLE_RESPONSE',
            { response: response.data, successMessage: 'Het geselecteerde component is succesvol verwijderd.' },
            { root: true }
          );
        })
        .catch((err) => store.commit('ADD_TOAST_ERROR', err, { root: true }));
    }

    watch(
      componentsInTool,
      () => {
        if (componentsInTool.value) {
          if (componentsInTool.value.length == oldComponentsInTool.value.length && componentsInTool.value.length > 0) {
            const getCorrespondingOldComponent = (component) => oldComponentsInTool.value.filter((comp) => comp.id == component.id)[0];
            if (componentsInTool.value.some((comp) => getCorrespondingOldComponent(comp).aantal != comp.aantal)) {
              onSubmit();
            }
          }
        }
        store.commit('toolscrud/SET_OLD_COMPONENTS_IN_TOOL', {
          identifier: toolsIdentifier.value,
          payload: JSON.parse(JSON.stringify(componentsInTool.value))
        });
      },
      { deep: true }
    );

    watch(
      [toolsData, componentsData],
      () => {
        if (toolsData.value) {
          werkelijkeToolsData.value = toolsData.value.werkelijkeTools;
        }
        if (!toolsData.value) return;
        const available = [];
        const inTool = [];

        componentsData.value.forEach((component) => {
          let toolComp = toolsData.value.components?.filter((c) => c.id === component.id)[0];
          if (toolComp?.aantal) {
            const aantal = toolComp?.aantal;
            for (const prop in component) {
              toolComp[prop] = component[prop];
            }
            toolComp.aantal = aantal;

            inTool.push(toolComp);
          } else {
            available.push(component);
          }
        });

        availableComponents.value = available;

        if (inTool.length) {
          store.commit('toolscrud/SET_CURRENT_COMPONENTS_IN_TOOL', { identifier: toolsIdentifier.value, payload: inTool });
        }
      },
      { immediate: true }
    );

    function goToGrid() {
      router.push(
        lastPage.value ?? {
          name: 'toolsview'
        }
      );
    }

    function goToWerkelijkeToolsCrud(isEdit) {
      router.push({
        name: 'werkelijketoolscrud',
        params: {
          isEdit: isEdit,
          identifier: baseIdentifier.value,
          toolId: id.value
        }
      });
    }

    function removeWerkelijkeToolFromTool() {
      let toDelete = store.state.dynamicgrid[werkelijkeToolsIdentifier.value].selectedRows[0];
      CrudService.deleteById('werkelijketools', toDelete.id)
        .then((response) => {
          if (response.data.success) {
            const indexToRemove = store.state.dynamicgrid[werkelijkeToolsIdentifier.value].rows.findIndex((value) => value === toDelete);
            store.state.dynamicgrid[werkelijkeToolsIdentifier.value].rows.splice(indexToRemove, 1);
            store.state.toolscrud[toolsIdentifier.value].toolsData.werkelijkeTools = store.state.dynamicgrid[werkelijkeToolsIdentifier.value].rows;
            store.state.dynamicgrid[werkelijkeToolsIdentifier.value].selectedRows = [];
          }
          store.commit(
            'HANDLE_RESPONSE',
            { response: response.data, successMessage: 'De geselecteerde werkelijke tool is succesvol verwijderd.' },
            { root: true }
          );
        })
        .catch((err) => store.commit('ADD_TOAST_ERROR', err, { root: true }));
    }

    function printLabels() {
      store.dispatch(
        'toolscrud/printLabels',
        wTGridRows.value.filter((x) => x.printLabel)
      );
    }

    return {
      componentMetadata,
      currentComponentsInTool,
      componentsMetadata,
      availableComponents,
      toolsMetadata,
      toolsData,
      toolsLoadingStatus,
      toolsValidationErrors,
      availableComponentsSelectedRow,
      componentsInToolSelectedRow,
      addComponentToTool,
      removeComponentFromTool,
      onSubmit,
      goToGrid,
      toolsIdentifier,
      componentsInToolIdentifier,
      availableComponentsIdentifier,
      werkelijkeToolsMetadata,
      werkelijkeToolsData,
      werkelijkeToolsIdentifier,
      werkelijkeToolsSelectedRow,
      goToWerkelijkeToolsCrud,
      removeWerkelijkeToolFromTool,
      printLabels,
      wTGridRows
    };
  }
};
</script>